"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initCtf = exports.Ctf = void 0;
var networks_1 = require("../config/networks");
var provider_1 = require("@opengsn/provider");
var ethers_1 = require("ethers");
var CtfArtifact = __importStar(require("../artifacts/contracts/CaptureTheFlag.sol/CaptureTheFlag.json"));
/**
 * a wrapper class for the CTF contract.
 * the only network-specific "leak" from this class is that the "capture()"
 * event returns a transaction object,
 * that the application should wait() until it gets mined.
 */
var Ctf = /** @class */ (function () {
    function Ctf(address, signer, gsnProvider) {
        this.address = address;
        this.gsnProvider = gsnProvider;
        this.blockDates = {};
        this.ethersProvider = signer.provider;
        this.gsnProvider = gsnProvider;
        this.theContract = new ethers_1.ethers.Contract(address, CtfArtifact.abi, signer);
        this.blockDates = {};
    }
    Ctf.prototype.getCurrentFlagHolder = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.theContract.currentHolder()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Ctf.prototype.listenToEvents = function (onEvent, onProgress) {
        var _this = this;
        // @ts-ignore
        var listener = function (from, to, event) { return __awaiter(_this, void 0, void 0, function () {
            var info;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getEventInfo(event)];
                    case 1:
                        info = _a.sent();
                        onEvent(info);
                        return [2 /*return*/];
                }
            });
        }); };
        this.theContract.on('FlagCaptured', listener);
        if (onProgress != undefined) {
            this.gsnProvider.registerEventListener(onProgress);
        }
    };
    Ctf.prototype.stopListenToEvents = function (onEvent, onProgress) {
        if (onProgress === void 0) { onProgress = null; }
        this.theContract.off(onEvent, null);
        this.gsnProvider.unregisterEventListener(onProgress);
    };
    Ctf.prototype.getBlockDate = function (blockNumber) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!!this.blockDates[blockNumber]) return [3 /*break*/, 2];
                        _a = this.blockDates;
                        _b = blockNumber;
                        _c = Date.bind;
                        return [4 /*yield*/, this.ethersProvider.getBlock(blockNumber).then(function (b) {
                                return b.timestamp * 1000;
                            })];
                    case 1:
                        _a[_b] = new (_c.apply(Date, [void 0, _d.sent()]))();
                        _d.label = 2;
                    case 2: return [2 /*return*/, this.blockDates[blockNumber]];
                }
            });
        });
    };
    Ctf.prototype.getEventInfo = function (e) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!e.args) {
                            return [2 /*return*/, {
                                    previousHolder: 'notevent',
                                    currentHolder: JSON.stringify(e)
                                }];
                        }
                        _a = {};
                        return [4 /*yield*/, this.getBlockDate(e.blockNumber)];
                    case 1: return [2 /*return*/, (_a.date = _b.sent(),
                            _a.previousHolder = e.args.previousHolder,
                            _a.currentHolder = e.args.currentHolder,
                            _a)];
                }
            });
        });
    };
    Ctf.prototype.getPastEvents = function (count) {
        var _a;
        if (count === void 0) { count = 5; }
        return __awaiter(this, void 0, void 0, function () {
            var currentBlock, lookupWindow, startBlock, logs, lastLogs;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.ethersProvider.getBlockNumber()];
                    case 1:
                        currentBlock = (_b.sent()) - 1;
                        lookupWindow = ((_a = global.network) === null || _a === void 0 ? void 0 : _a.pastEventsQueryMaxPageSize) || 30 * 24 * 3600 / 12;
                        startBlock = Math.max(1, currentBlock - lookupWindow);
                        return [4 /*yield*/, this.theContract.queryFilter(this.theContract.filters.FlagCaptured(), startBlock)];
                    case 2:
                        logs = _b.sent();
                        return [4 /*yield*/, Promise.all(logs.slice(-count).map(function (e) { return _this.getEventInfo(e); }))];
                    case 3:
                        lastLogs = _b.sent();
                        return [2 /*return*/, lastLogs];
                }
            });
        });
    };
    Ctf.prototype.getSigner = function () {
        return this.theContract.signer.getAddress();
    };
    Ctf.prototype.capture = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.ethersProvider.getGasPrice().then(function (price) { return console.log('== gas price=', price.toString()); });
                        return [4 /*yield*/, this.theContract.captureTheFlag()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Ctf.prototype.getGsnStatus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var relayClient, ci;
            var _this = this;
            return __generator(this, function (_a) {
                relayClient = this.gsnProvider.relayClient;
                ci = relayClient.dependencies.contractInteractor;
                return [2 /*return*/, {
                        relayHubAddress: ci.relayHubInstance.address,
                        forwarderAddress: ci.forwarderInstance.address,
                        paymasterAddress: ci.paymasterInstance.address,
                        getPaymasterBalance: function () { return ci.paymasterInstance.getRelayHubDeposit(); },
                        getActiveRelayers: function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                return [2 /*return*/, relayClient.dependencies.knownRelaysManager.refresh().then(function () {
                                        return relayClient.dependencies.knownRelaysManager.allRelayers.length;
                                    })];
                            });
                        }); }
                    }];
            });
        });
    };
    return Ctf;
}());
exports.Ctf = Ctf;
function initCtf() {
    return __awaiter(this, void 0, void 0, function () {
        var web3Provider, provider, network, chainId, net, netid, maxViewableGasLimit, gsnConfig, gsnProvider, provider2, signer;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    web3Provider = window.ethereum;
                    if (!web3Provider)
                        throw new Error('No "window.ethereum" found. do you have Metamask installed?');
                    web3Provider.on('chainChanged', function (chainId) {
                        console.log('chainChanged', chainId);
                        window.location.reload();
                    });
                    web3Provider.on('accountsChanged', function (accs) {
                        console.log('accountChanged', accs);
                        window.location.reload();
                    });
                    provider = new ethers_1.ethers.providers.Web3Provider(web3Provider);
                    return [4 /*yield*/, provider.getNetwork()];
                case 1:
                    network = _a.sent();
                    chainId = network.chainId;
                    net = global.network = networks_1.networks[chainId];
                    return [4 /*yield*/, provider.send('net_version', [])];
                case 2:
                    netid = _a.sent();
                    console.log('chainid=', chainId, 'networkid=', netid);
                    if (chainId !== parseInt(netid))
                        console.warn("Incompatible network-id ".concat(netid, " and ").concat(chainId, ": for Metamask to work, they should be the same"));
                    if (!net || !net.paymaster) {
                        //ganache/hardnat network
                        if (!chainId.toString().match(/1337/) || !window.location.href.match(/localhost|127.0.0.1/))
                            throw new Error("Unsupported network (chainId=".concat(chainId, ") . please switch to one of: ") + Object.values(networks_1.networks).map(function (n) { return n.name; }).filter(function (n) { return n; }).join(' / '));
                        else
                            throw new Error('To run locally, you must run "yarn evm" and then "yarn deploy" before "yarn react-start" ');
                    }
                    maxViewableGasLimit = chainId === 6 ? 5e6 : undefined;
                    gsnConfig = {
                        maxViewableGasLimit: maxViewableGasLimit,
                        relayLookupWindowBlocks: global.network.relayLookupWindowBlocks || 600000,
                        relayRegistrationLookupBlocks: global.network.relayRegistrationLookupBlocks || 600000,
                        loggerConfiguration: { logLevel: 'debug' },
                        pastEventsQueryMaxPageSize: global.network.pastEventsQueryMaxPageSize || Number.MAX_SAFE_INTEGER,
                        paymasterAddress: net.paymaster
                    };
                    console.log('== gsnconfig=', gsnConfig);
                    gsnProvider = provider_1.RelayProvider.newProvider({ provider: web3Provider, config: gsnConfig });
                    return [4 /*yield*/, gsnProvider.init()];
                case 3:
                    _a.sent();
                    provider2 = new ethers_1.ethers.providers.Web3Provider(gsnProvider);
                    signer = provider2.getSigner();
                    return [2 /*return*/, new Ctf(net.ctf, signer, gsnProvider)];
            }
        });
    });
}
exports.initCtf = initCtf;
